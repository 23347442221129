@import '../../../styles/customMediaQueries.css';

.wrapper {
  margin-top: calc(60px + var(--topbarHeight));
  width: 100%;
  background-color: #f5f7fa;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15%;

  @media (max-width: 768px) {
    margin-top: 0;
    padding: 20px;
    flex-direction: column;
  }
}

.searchButton {
  cursor: pointer;
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 5px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  margin-left: 10px;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
}

.searchInput {
  background-color: white;
  border-bottom: none;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 3%);
  border-radius: 5px;
  height: 50px;
  margin-right: 10px;
  padding-left: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
